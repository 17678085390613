import React from 'react'
import { Link } from 'react-router-dom'

const DrawMoney = () => {
    return (
        <Link to='/withdraw-fund'>
            <button className='button-08491'>
                Withdraw Money
            </button>
        </Link>
    )
}

export default DrawMoney
