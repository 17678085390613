import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faHourglassHalf, faSquarePollHorizontal, faTrophy, faUserTie } from '@fortawesome/free-solid-svg-icons'

const NavbarBottom = () => {
    return (
        <div className="footerHere hidden-print">
            <div className='footerNavbar'>
                <Link to='/' className='footerNavbar__bottom-child link-style-none'>
                    <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                    Home
                </Link>
                <Link to='/profile' className='footerNavbar__bottom-child link-style-none'>
                    <FontAwesomeIcon icon={faUserTie}></FontAwesomeIcon>
                    Profile
                </Link>
                <Link to='/bet-history' className='footerNavbar__bottom-child link-style-none'>
                    <FontAwesomeIcon icon={faHourglassHalf}></FontAwesomeIcon>
                    Bet History
                </Link>
                <Link to='/win-history' className='footerNavbar__bottom-child link-style-none'>
                    <FontAwesomeIcon icon={faTrophy}></FontAwesomeIcon>
                    Win History
                </Link>
                <Link className='footerNavbar__bottom-child link-style-none' to='/result'>
                    <FontAwesomeIcon icon={faSquarePollHorizontal}></FontAwesomeIcon>
                    Results
                </Link>
            </div>
        </div>

    )
}

export default NavbarBottom
