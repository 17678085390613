import React from 'react'
import './css/gameArchive.css'
import { Link, useParams } from 'react-router-dom';
import NavbarTop from '../navbar/navbarTop'
import { CgCardSpades } from "react-icons/cg";
import NavbarBottom from '../navbar/navbarBottom'
import { TbCards } from "react-icons/tb";

const GameArchive = () => {
    const { id } = useParams()

    return (
        <div>
            <NavbarTop />
            <div className="game-archive">
                <div className="game-archive__title">Game Archive</div>
                <div className="game-archive__content">
                    <Link to={`/single-patti/${id}`} className="game-archive--singlePatti game-archive--patti">
                        < CgCardSpades />
                        <p>Single</p>
                    </Link>
                    <Link to={`/triple-patti/${id}`} className="game-archive--triplePatti game-archive--patti">
                        < TbCards />
                        <p>Patti</p>
                    </Link>
                </div>
            </div >
            <NavbarBottom />
        </div >
    )
}

export default GameArchive
