import React, { useEffect, useState } from 'react'
import NavbarTop from '../navbar/navbarTop'
import './css/profile.css'
import NavbarBottom from '../navbar/navbarBottom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { auth } from '../config/firebase'
import { query, collection, where, getDocs } from 'firebase/firestore'
import { db } from '../config/firebase'
import { signOut } from 'firebase/auth'
import { faArrowRight, faChartSimple, faCheck, faHeartbeat, faLock, faQuestion, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const logoutFunc = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

const ProfilePage = () => {
    const [profile, setProfile] = useState()
    const [walletBalance, setWalletBalance] = useState('-');

    const fetchUserProfile = async () => {
        try {
            const q = query(collection(db, "userProfile"), where("emailId", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.log("No such document!");
            } else {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    setWalletBalance(doc.data().wallet)
                });
            }
        } catch (error) {
            console.error("Error fetching document:", error);
        }
    };
    useEffect(() => {
        setProfile(auth.currentUser)
        fetchUserProfile()
    }, [auth])

    return (
        <div>
            <NavbarTop />
            <div className="profile-details">
                <div className="profile-image">
                    <img id='imgProfilePic' src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D" alt="" />
                </div>
                <p className="profileDisplayName">{profile?.displayName}</p>
                <button>Balance : {walletBalance}</button>
            </div>
            <div className="account-links">
                <Link to="statement" className='link-NOSTYLE acountlinkchild'>
                    <div className="icontext-profile displayFlexRow">
                        <FontAwesomeIcon icon={faChartSimple}></FontAwesomeIcon>
                        <p>Account Statement</p>
                    </div>
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                </Link>
                <Link className='link-NOSTYLE acountlinkchild' to='security'>
                    <div className="icontext-profile displayFlexRow">
                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                        <p>Account Security</p>
                    </div>
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                </Link>
                <Link to='patti-list' className='link-NOSTYLE acountlinkchild'>
                    <div className="icontext-profile displayFlexRow">
                        <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                        <p>Patti List</p>
                    </div>
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                </Link>
                <Link className='link-NOSTYLE acountlinkchild'>
                    <div className="icontext-profile displayFlexRow">
                        <FontAwesomeIcon icon={faHeartbeat}></FontAwesomeIcon>
                        <p>Beginner Tutorial</p>
                    </div>
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                </Link>
                <Link className='link-NOSTYLE acountlinkchild'>
                    <div className="icontext-profile displayFlexRow">
                        <FontAwesomeIcon icon={faQuestionCircle}></FontAwesomeIcon>
                        <p>Customer Service</p>
                    </div>
                    <div className="arrow">
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                    </div>
                </Link>
                <div className="centerthebutton">
                    <button className="logoutService" onClick={logoutFunc}>
                        Log Out
                    </button>
                </div>

            </div>
            <NavbarBottom />
        </div>
    )
}

export default ProfilePage
