import React from 'react'
import './css/add-draw.css'
import { Link } from 'react-router-dom'

const AddMoney = () => {
    return (
        <Link className='button-0849-link' to='/add-fund'>

            <button className='button-0849'>
                Add Money
            </button>
        </ Link>
    )
}

export default AddMoney
