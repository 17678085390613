import React from 'react'
import ResultTable from '../components/resultTable'
import NavbarTop from '../navbar/navbarTop'
import NavbarBottom from '../navbar/navbarBottom'

const ResultPage = () => {
    return (
        <div>
            <NavbarTop />
            <ResultTable />
            <NavbarBottom />
        </div>
    )
}

export default ResultPage
