import React, { useState } from 'react';
import illustration from './../assets/3293465.jpg';
import './authentication.css';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebase'; // Import the auth module from your Firebase configuration

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Check if email contains @gmail.com, if not, append it
            const completeEmail = email.includes('@gmail.com') ? email : `${email}@gmail.com`;

            // Sign in user with email and password
            await signInWithEmailAndPassword(auth, completeEmail, password);

            // Redirect to home page or any other protected route upon successful login
            navigate('/');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='login-page'>
            <p className='login-header'>Login Your Account</p>
            <img id='login-illustration' src={illustration} alt="" />
            <form className="cred-group" onSubmit={handleSubmit}>
                <label htmlFor="" className="cred-child-label">
                    <p>Email ID : </p>
                    <input
                        type="text"
                        className="cred-child"
                        placeholder="Enter your Username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <label htmlFor="" className="cred-child-label">
                    <p>Password : </p>
                    <input
                        type="password"
                        className="cred-child"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <button id='credButton' type="submit">Login Now</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
            <Link to='/register' style={{ textDecoration: 'none', color: 'red' }}><p>Don't Have an Account? Click Here</p></Link>
        </div>
    );
}

export default LoginPage;
