import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import './css/openBazaar.css';
import { db } from '../config/firebase';

const OpenBazaar2 = () => {
    const [game, setGame] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear();
            const dateString = `${day}-${month}-${year}`;
            console.log(dateString);

            const querySnapshot = await getDocs(collection(db, dateString));
            const allData = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                allData.push({ id: doc.id, ...doc.data() });
            });

            // Filter out any items in the allData array that have undefined gameName
            const validData = allData.filter(item => item.gameName);

            // Sort the validData array by gameName in ascending order
            validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

            console.log('Sorted data:', validData);

            // Set the state with the sorted array
            setGame(validData);
        };
        fetchData();
    }, []);

    function getButtonState(gameTimeStart, gameTimeEnd, id) {
        const currentDate = new Date();
        const currentHours = currentDate.getHours(); // Get current hour in 24-hour format
        const currentMinutes = currentDate.getMinutes().toString().padStart(2, '0'); // Get current minutes with leading zero

        // Split start and end times into hours and minutes
        const [startHour, startMinute] = gameTimeStart.split(':').map(Number);
        const [endHour, endMinute] = gameTimeEnd.split(':').map(Number);

        // Check if current time is within the game time window
        if (currentHours > startHour && currentHours < endHour) {
            // Game is currently active
            return (
                <Link to={`/game/gamearchive/${id}`} className='a-href--button-openBazaar'>
                    <button style={{ backgroundColor: 'green' }}>Play Game</button>
                </Link>
            );
        } else if (currentHours === startHour) {
            // Check if game is starting within the same hour
            if (currentMinutes < startMinute) {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'green' }}>Play Game</button>
                    </Link>
                );
            } else {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'green' }}>Play Game</button>
                    </Link>
                );
            }
        } else if (currentHours === endHour) {
            // Check if game is ending within the same hour
            if (currentMinutes <= endMinute) {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'green' }}>Play Game</button>
                    </Link>
                );
            } else {
                return <button disabled style={{ backgroundColor: 'red' }}>Completed</button>;
            }
        } else if (currentHours < startHour) {
            // Game has not started yet
            return (
                <Link to={`/game/gamearchive/${id}`}>
                    <button style={{ backgroundColor: 'green' }}>Play Game</button>
                </Link>
            );
        } else {
            // Game has already ended
            return <button disabled style={{ backgroundColor: 'red' }}>Completed</button>;
        }
    }


    // Sort the game array based on the gameName property
    const sortedGame = game.sort((a, b) => parseInt(a.gameName) - parseInt(b.gameName));

    return (
        <>
            <div className='openBazaarContainer'>
                {sortedGame
                    .filter(item => item.fatafatName === 'kalyanifatafat') // Filter for Kolkata Bazaar
                    .map((item) => (
                        <div className='openBazaarContainer__child' key={item.gameName}>
                            <div className="openBazarxPadding">
                                <p className='openBazarBaziName'>Bazi {item.gameName} - {item.fatafatName}</p>
                                <p><b>Game Time :</b>  {item.gameTimeStart} - {item.gameTimeEnd}</p>
                                <p><b>Result :</b> {item.resultTime}</p>
                            </div>
                            {/* Call getButtonState to render button based on time */}
                            {getButtonState(item.gameTimeStart, item.gameTimeEnd, item.id)}
                        </div>
                    ))}
            </div>


        </>

    );
};

export default OpenBazaar2;
