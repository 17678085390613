import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Preloader from '../components/preloader';

const ProtectedRoute = () => {
    const [isAuthenticated, setAuthenticated] = useState(null); // Initial state set to null
    const [passwordChange, setPasswordChange] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            // console.log(user.email);

            if (user) {
                try {
                    const userEmail = user.email;
                    console.log(user.email);
                    const userQuery = query(
                        collection(db, 'userProfile'),
                        where('emailId', '==', '2@gmail.com'),
                        where('status', '==', 'active')
                    );
                    const userQueryPassword = query(
                        collection(db, 'userProfile'),
                        where('emailId', '==', userEmail)
                    );
                    const querySnapshot = await getDocs(userQuery);
                    const queryPassword = await getDocs(userQueryPassword);

                    if (!querySnapshot.empty) {
                        // User is authenticated and has an active account
                        setAuthenticated(true);
                        console.log('Check');
                        // Since querySnapshot contains multiple documents, we need to iterate over them
                        querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            setPasswordChange(data.passwordStatus);

                            if (data.passwordStatus === false) {
                                // Check if the current location is not '/profile/security'
                                if (window.location.pathname !== '/profile/security') {
                                    window.location.href = '/profile/security';
                                }
                            }
                        });
                    } else {
                        // User profile not found or account is not active
                        alert('ACCOUNT DISABLED');
                        setAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                    setAuthenticated(false);
                }
            } else {
                // User is not authenticated
                setAuthenticated(false);
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []); // Empty dependency array to ensure this effect runs only once

    if (isAuthenticated === null) {
        // Still determining authentication state
        return <Preloader />;
    } else if (isAuthenticated) {
        // User is authenticated
        console.log('User is authenticated:', isAuthenticated);
        return <Outlet />;
    } else {
        // User is not authenticated
        console.log('User is not authenticated');
        return <Navigate to="/welcome" />;
    }
};

export default ProtectedRoute;
