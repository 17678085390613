import React, { useEffect, useState } from 'react';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import './css/security.css';
import { db } from '../config/firebase';
import { getAuth, updatePassword, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import { query, collection, where, getDocs, getDoc, setDoc } from 'firebase/firestore';

const ProfileSecurity = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setEmail(user.email);
            } else {
                setEmail('');
            }
        });

        return () => unsubscribe();
    }, []);

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match.');
            return;
        }

        setIsLoading(true);

        try {
            const auth = getAuth();
            const user = auth.currentUser;
            await updatePassword(user, newPassword);

            // Query Firestore to find the document with the matching emailId
            const userQuery = query(collection(db, 'userProfile'), where('emailId', '==', user.email));
            const querySnapshot = await getDocs(userQuery);

            if (!querySnapshot.empty) {
                const userDocRef = querySnapshot.docs[0].ref;

                // Check if password field exists in the document
                const doc = await getDoc(userDocRef);
                const docData = doc.exists() ? doc.data() : {};

                // Update the fields
                const updatedData = {
                    passwordStatus: true,
                    password: confirmPassword // This will set or overwrite the password field
                };

                // Use setDoc to update the document with the updatedData
                await setDoc(userDocRef, { ...docData, ...updatedData }, { merge: true });
                setSuccess('Password updated successfully.');
                setError('');
            } else {
                setError('User profile not found.');
                console.log('User profile not found.');
            }
        } catch (error) {
            setError('Error updating password: ' + error.message);
            console.log('Error updating password: ', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendResetLink = async () => {
        setIsLoading(true);

        try {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, email);
            setSuccess('Password reset link sent to your email.');
            setError('');
        } catch (error) {
            setError('Error sending password reset link: ' + error.message);
            setSuccess('');
            console.log('Error sending password reset link: ', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <NavbarTop />
            <div className="security-page-container">
                <div className="security-header-content">
                    <h2>Secure Your Account</h2>
                    <p>It's a good practice to change passwords frequently to keep your account safe and secure.</p>
                </div>
                <div className="security-content">
                    <label htmlFor="newPassword" className="security-content-label">
                        <p>Enter New Password</p>
                        <input
                            type="password"
                            id="newPassword"
                            placeholder='Enter New Password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </label>
                    <label htmlFor="confirmPassword" className="security-content-label">
                        <p>Confirm New Password</p>
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder='Confirm New Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </label>
                    <button
                        className='changePasscode'
                        onClick={handleChangePassword}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Updating...' : 'Change Password'}
                    </button>
                    <div className="divider">or</div>
                    <button
                        className='sendResetLink changePasscode'
                        onClick={handleSendResetLink}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending...' : 'Send Password Reset Link to Email'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
};

export default ProfileSecurity;
