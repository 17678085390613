import React, { useEffect } from 'react'
import './css/home.css'
import NavbarTop from '../navbar/navbarTop'
import AutoSlider from '../components/autoSlider';
import QuickLinks from '../components/quickLinks';
import AddMoney from '../components/addMoney';
import DrawMoney from '../components/drawMoney';
import OpenBazaar from '../components/openBazaar';
import ResultTable from '../components/resultTable';
import NavbarBottom from '../navbar/navbarBottom';
import { auth } from '../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import OpenBazaar2 from '../components/openBazaar2';

const Home = () => {


    useEffect(() =>
        onAuthStateChanged(auth, (user) => {
            if (user) {

                console.log(user);
            } else {
            }
        }), [auth])
    const images = [
        'https://www.shutterstock.com/image-vector/web-banner-online-casino-button-600nw-2068577267.jpg',
        'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/casino-banner-design-template-d50428bdf34d5f9160f513e08ddf145b_screen.jpg?ts=1659342147',
        'https://static.vecteezy.com/system/resources/thumbnails/005/525/160/small/online-casino-banner-with-button-smartphone-neon-slot-machine-casino-roulette-and-poker-chips-with-neon-rhombus-frames-and-hologram-of-digital-rings-vector.jpg'
    ];
    return (
        <div>
            <NavbarTop />
            <div className="marquee-Text">
                <marquee behavior="" direction="">
                    This Game is Presented for Entertainment Purposes Only . Please Play On Your Own Risk :)
                </marquee>
            </div>
            <AutoSlider items={images} interval={2000} />
            <div className="quickLinks" style={{ paddingTop: '10px' }}>
                <p className='homeHeadings'>

                    Quick Links
                </p>
                <div className="quickLink-container">
                    <QuickLinks />
                </div>
            </div>
            <div className="add-withdraw-container">
                <AddMoney />
                <DrawMoney />
            </div>
            <div className="gameContainer-onlyOne" onClick={() => {
                window.location.href = "/game";
            }}>
                <div className="kolkataFatafat gameContainer-onlyOne-child">
                    KOLKATA FATAFAT
                </div>
            </div>
            <div className="gameContainer-onlyOne" onClick={() => {
                window.location.href = "/game-2";
            }}>
                <div className="kalyanifatafat gameContainer-onlyOne-child">
                    FATAFAT DELHI
                </div>
            </div>
            <div className="bazaarContainer-Home">
                <p className='homeHeadings'>Kolkata Bazaar</p>

                <OpenBazaar />
                <p className='homeHeadings'>Delhi Fatafat</p>

                <OpenBazaar2 />
            </div>
            <div className="resultsDivHome" >
                <p className='homeHeadings'>Today Result</p>
                <ResultTable />
            </div>
            <NavbarBottom />
        </div>
    )
}

export default Home
