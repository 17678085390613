import React, { useRef, useEffect, useState } from 'react';
import './css/autoSlider.css';

const AutoSlider = ({ items, interval }) => {
    const [index, setIndex] = useState(0);
    const sliderRef = useRef(null);
    const intervalIdRef = useRef(null);

    useEffect(() => {
        const slider = sliderRef.current;

        const scrollNext = () => {
            if (slider.scrollLeft + slider.offsetWidth >= slider.scrollWidth) {
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth' // Smooth scrolling to the beginning
                });
            } else {
                slider.scrollTo({
                    left: slider.scrollLeft + slider.offsetWidth,
                    behavior: 'smooth' // Smooth scrolling to the next slide
                });
            }
            setIndex((prevIndex) => (prevIndex + 1) % items.length); // Increment index
        };

        intervalIdRef.current = setInterval(scrollNext, interval);

        // Pause auto-scrolling when hovering over the slider
        const handleMouseEnter = () => clearInterval(intervalIdRef.current);
        slider.addEventListener('mouseenter', handleMouseEnter);

        // Resume auto-scrolling when mouse leaves the slider
        const handleMouseLeave = () => {
            intervalIdRef.current = setInterval(scrollNext, interval);
        };
        slider.addEventListener('mouseleave', handleMouseLeave);

        return () => clearInterval(intervalIdRef.current); // Cleanup on unmount
    }, [interval, items.length, items]);

    return (
        <div className="slider-container">
            <div className="slider" ref={sliderRef}>
                {items.map((item, i) => (
                    <div key={i} className={i === index ? 'slide active' : 'slide'}>
                        <img src={item} style={{ height: '200px' }} alt="" />
                    </div>
                ))}
            </div>
            <div className="dots">
                {items.map((_, i) => (
                    <span key={i} className={i === index ? 'dot active' : 'dot'}></span>
                ))}
            </div>
        </div>
    );
};

export default AutoSlider;
