import { Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './routes/home';
import GamePage from './routes/game';
import GameArchive from './routes/gameArchive';
import ProfilePage from './routes/profile';
import SinglePatti from './routes/singlePatti';
import TriplePatti from './routes/triplePatti';
import LoginPage from './authentication/login';
import ProfileSecurity from './routes/profileSecurity';
import BetHistory from './routes/betHistory';
import WinHistory from './routes/winHistory';
import RegisterPage from './authentication/register';
import WelcomePage from './authentication/welcome';
import AddFund from './routes/addFund';
import WithdrawFund from './routes/withdrawFund';
import ProtectedRoute from './routes/protectedRoute';
import ProtectedCheckAuth from './routes/protectCheckAuth';
import AccountStatement from './routes/accountStatement';
import PattiList from './routes/pattiList';
import ResultPage from './routes/resultPage';
import POScontent from './components/posContent';
import GamePage2 from './routes/game2';

function App() {
  return (
    <Routes>
      <Route path='x' element={<POScontent />}></Route>
      {/* <Route path='/login' element={<ProtectedCheckAuth />}> */}
      <Route path='/login' element={<LoginPage />} />
      {/* </Route> */}
      {/* <Route path='/register' element={<ProtectedCheckAuth />}> */}
      <Route path='/register' element={<RegisterPage />} />
      {/* </Route> */}
      {/* <Route path='/welcome' element={<ProtectedCheckAuth />}> */}
      <Route path='/welcome' element={<WelcomePage />} />
      {/* </Route> */}
      <Route path='/' element={<ProtectedRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/add-fund' element={<AddFund />} />
        <Route path='/withdraw-fund' element={<WithdrawFund />} />
        <Route path='/result' element={<ResultPage />} />
        <Route path='/game' element={<GamePage2 />} />
        <Route path='/game-2' element={<GamePage />} />

        <Route path='/game/gamearchive/:id' element={<GameArchive />} />
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/profile' element={<Outlet />}>
          <Route path='patti-list' element={<PattiList />} />
          <Route path='security' element={<ProfileSecurity />} />
          <Route path='statement' element={<AccountStatement />} />
        </Route>
        <Route path='/triple-patti/:id' element={<TriplePatti />} />
        <Route path='/single-patti/:id' element={<SinglePatti />} />
        <Route path='/bet-history' element={<BetHistory />} />
        <Route path='/win-history' element={<WinHistory />} />
      </Route>
      <Route path='*' element={<>ERROR 404 - App Page Not Found</>} />
    </Routes>
  );
}

export default App;
