import React from 'react';
import './css/pos.css';

const POScontent = ({ gameData }) => {

    const handlePrintClick = () => {
        const userConfirmed = window.confirm('Do you want to print the receipt?');
        if (userConfirmed) {
            window.print();
        }
    };

    return (
        <>
            <div className="ticket">
                <p className="centered" id='gamename-gamedate-print'>Bazi {gameData.gameName}</p>
                <p style={{ fontSize: '10px' }}>{gameData.gameTime} - {gameData.gameDate}</p>
                <p style={{ letterSpacing: '2px', marginBottom: '10px' }}>
                    {gameData.gameType}
                </p>
                <table style={{ marginTop: '10px' }}>
                    <thead>
                        <tr>
                            <th className="quantity">Number</th>
                            <th className="description">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameData.gameAmountandDigit?.map((bet, index) => (
                            <tr key={index}>
                                <td className="quantity">{bet.digit}</td>
                                <td className="description">{bet.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p className="centered">Thanks for your purchase!
                    <br />
                    Fatafat Daily
                </p>
            </div>
            <button className="printOut hidden-print " style={
                {
                    width: '100%',
                    padding: '10px',
                    background: 'red',
                    color: '#fff',
                    border: 'none'
                }
            } onClick={handlePrintClick}>Print Out</button>
        </>

    );
}

export default POScontent;
