import React, { useState, useEffect } from 'react';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import './css/fund.css';
import { Link } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { query, getDocs, collection, where, addDoc, updateDoc, doc } from 'firebase/firestore';

const WithdrawFund = () => {
    const [walletBalance, setWalletBalance] = useState('-');
    const [amount, setAmount] = useState('');
    const [upiId, setUpiId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchUserProfile = async () => {
            console.log(auth.currentUser);
            try {
                const q = query(collection(db, "userProfile"), where("emailId", "==", auth.currentUser.email));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.log("No such document!");
                } else {
                    querySnapshot.forEach((doc) => {
                        console.log(doc.id, " => ", doc.data());
                        setWalletBalance(doc.data().wallet);
                    });
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
        } else {
            console.log("No authenticated user.");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const currentUser = auth.currentUser;

        // Validate minimum withdrawal amount
        if (parseFloat(amount) < 100) {
            alert("Minimum withdrawal amount is 100.");
            setIsSubmitting(false);
            return;
        }

        // Deduct the amount from the wallet balance
        const newWalletBalance = parseFloat(walletBalance) - parseFloat(amount);

        if (newWalletBalance < 0) {
            alert("Insufficient wallet balance.");
            setIsSubmitting(false);
            return;
        }

        const fundRequest = {
            fundId: '',
            upiId,
            amount,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            emailId: currentUser.email,
            uid: currentUser.uid,
        };

        try {
            // Post the request to Firestore
            await addDoc(collection(db, "fund-withdraw-request"), fundRequest);

            // Update the wallet balance in Firestore
            const q = query(collection(db, "userProfile"), where("emailId", "==", currentUser.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userProfileDoc = querySnapshot.docs[0];
                const userProfileRef = doc(db, "userProfile", userProfileDoc.id);
                await updateDoc(userProfileRef, { wallet: newWalletBalance });
                setWalletBalance(newWalletBalance.toString());
            }

            alert("Fund withdrawal request added successfully");
        } catch (error) {
            console.error("Error adding fund withdrawal request:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleQuickAmountClick = (value) => {
        setAmount(value.toString());
    };

    return (
        <div>
            <NavbarTop />
            <div className="addFundPage-container">
                <div className="addFund-content">
                    <div className="content-withdraw-page--fund-show">
                        <div className="balance-account--withdraw-fund">
                            <div className="wallet-balance--01">
                                <h3>Wallet Balance</h3>
                                <p>₹ {walletBalance}</p>
                            </div>
                            <Link>
                                <button className="addFund-01">
                                    Add Fund
                                </button>
                            </Link>
                        </div>
                    </div>
                    <h1 className='withdrawFundHeading'>WITHDRAW FUND</h1>
                    <p className='fund-add-detail-p'>Enter the details of the fund you want to withdraw</p>
                    <div className="quick-amount-buttons" style={{ marginTop: "-10px", marginBottom: '40px' }}>
                        {[100, 200, 500, 1000, 2000].map((value, index) => (
                            <button
                                key={index}
                                className="quick-amount-button"
                                onClick={() => handleQuickAmountClick(value)}
                            >
                                {value}
                            </button>
                        ))}
                    </div>
                    <form onSubmit={handleSubmit} className="fundstransfer-data">
                        <label htmlFor="" className="fundstransfer-child">
                            Amount To Be Withdrawn
                            <input
                                type="text"
                                placeholder="ENTER WITHDRAWAL AMOUNT"
                                className="fundstransfer-input"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                required
                            />
                        </label>
                        <label htmlFor="" className="fundstransfer-child">
                            UPI ID
                            <input
                                type="text"
                                placeholder="ENTER UPI ID"
                                className="fundstransfer-input"
                                value={upiId}
                                onChange={(e) => setUpiId(e.target.value)}
                                required
                            />
                        </label>
                        <div className="centerthebutton" style={{ width: '100%' }}>
                            <button type="submit" className="submitFundRequest" disabled={isSubmitting}>
                                {isSubmitting ? "ADDING REQUEST" : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
};

export default WithdrawFund;
