import React from 'react'
import illustration from './../assets/3293465.jpg'
import './authentication.css'
import { Link } from 'react-router-dom'
const WelcomePage = () => {
    return (
        <div className='login-page'>
            <img id='login-illustration' src={illustration} alt="" />
            <p className='login-header' style={{ marginTop: '10px' }}>Welcome</p>
            <p className="style-welcome">
                Welcome to Fatafat Daily. Take the First Step to Get into the Application
            </p>
            <form className="cred-group">
                <Link className='linkInCred' to="/login">
                    <button className="loginCred-group" aria-label="Login to your account">
                        Login Account
                    </button>
                </Link>
                <Link className='linkInCred' to="/register">
                    <button className="registerCred-group" aria-label="Register a new account">
                        Register New Account
                    </button>
                </Link>
            </form>
        </div>
    )
}

export default WelcomePage
