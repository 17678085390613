import React, { useEffect } from 'react'
import NavbarTop from '../navbar/navbarTop'
import './css/pattilist.css'
import NavbarBottom from '../navbar/navbarBottom'

const PattiList = () => {

    const pattiList = [
        { number: '000' },
        { number: '100' },
        { number: '200' },
        { number: '300' },
        { number: '400' },
        { number: '500' },
        { number: '600' },
        { number: '700' },
        { number: '800' },
        { number: '900' },
        { number: '127' },
        { number: '678' },
        { number: '345' },
        { number: '120' },
        { number: '789' },
        { number: '456' },
        { number: '123' },
        { number: '890' },
        { number: '567' },
        { number: '234' },
        { number: '190' },
        { number: '777' },
        { number: '444' },
        { number: '111' },
        { number: '888' },
        { number: '555' },
        { number: '222' },
        { number: '999' },
        { number: '666' },
        { number: '333' },
        { number: '280' },
        { number: '560' },
        { number: '570' },
        { number: '580' },
        { number: '590' },
        { number: '140' },
        { number: '150' },
        { number: '160' },
        { number: '170' },
        { number: '180' },
        { number: '370' },
        { number: '470' },
        { number: '480' },
        { number: '490' },
        { number: '130' },
        { number: '230' },
        { number: '330' },
        { number: '340' },
        { number: '350' },
        { number: '360' },
        { number: '460' },
        { number: '380' },
        { number: '390' },
        { number: '670' },
        { number: '680' },
        { number: '690' },
        { number: '240' },
        { number: '250' },
        { number: '260' },
        { number: '270' },
        { number: '550' },
        { number: '290' },
        { number: '660' },
        { number: '238' },
        { number: '248' },
        { number: '258' },
        { number: '268' },
        { number: '278' },
        { number: '288' },
        { number: '450' },
        { number: '235' },
        { number: '119' },
        { number: '129' },
        { number: '139' },
        { number: '149' },
        { number: '159' },
        { number: '169' },
        { number: '179' },
        { number: '189' },
        { number: '199' },
        { number: '118' },
        { number: '137' },
        { number: '237' },
        { number: '337' },
        { number: '347' },
        { number: '357' },
        { number: '367' },
        { number: '377' },
        { number: '116' },
        { number: '117' },
        { number: '578' },
        { number: '236' },
        { number: '336' },
        { number: '157' },
        { number: '158' },
        { number: '799' },
        { number: '448' },
        { number: '467' },
        { number: '233' },
        { number: '469' },
        { number: '145' },
        { number: '146' },
        { number: '246' },
        { number: '346' },
        { number: '446' },
        { number: '267' },
        { number: '899' },
        { number: '115' },
        { number: '459' },
        { number: '126' },
        { number: '479' },
        { number: '669' },
        { number: '679' },
        { number: '689' },
        { number: '699' },
        { number: '780' },
        { number: '178' },
        { number: '124' },
        { number: '125' },
        { number: '667' },
        { number: '668' },
        { number: '579' },
        { number: '255' },
        { number: '355' },
        { number: '455' },
        { number: '447' },
        { number: '790' },
        { number: '223' },
        { number: '224' },
        { number: '478' },
        { number: '299' },
        { number: '399' },
        { number: '147' },
        { number: '247' },
        { number: '266' },
        { number: '366' },
        { number: '466' },
        { number: '566' },
        { number: '477' },
        { number: '135' },
        { number: '334' },
        { number: '588' },
        { number: '228' },
        { number: '256' },
        { number: '112' },
        { number: '113' },
        { number: '358' },
        { number: '557' },
        { number: '990' },
        { number: '225' },
        { number: '488' },
        { number: '489' },
        { number: '499' },
        { number: '166' },
        { number: '356' },
        { number: '122' },
        { number: '880' },
        { number: '368' },
        { number: '134' },
        { number: '144' },
        { number: '389' },
        { number: '245' },
        { number: '688' },
        { number: '599' },
        { number: '239' },
        { number: '177' },
        { number: '114' },
        { number: '359' },
        { number: '558' },
        { number: '379' },
        { number: '226' },
        { number: '155' },
        { number: '778' },
        { number: '148' },
        { number: '338' },
        { number: '249' },
        { number: '556' },
        { number: '449' },
        { number: '369' },
        { number: '559' },
        { number: '569' },
        { number: '227' },
        { number: '138' },
        { number: '788' },
        { number: '257' },
        { number: '339' },
        { number: '259' },
        { number: '269' },
        { number: '378' },
        { number: '289' },
        { number: '677' },
        { number: '344' },
        { number: '156' },
        { number: '445' },
        { number: '220' },
        { number: '889' },
        { number: '349' },
        { number: '133' },
        { number: '440' },
        { number: '388' },
        { number: '136' },
        { number: '335' },
        { number: '110' },
        { number: '229' },
        { number: '770' },
        { number: '348' },
        { number: '457' },
        { number: '188' },
        { number: '279' },
        { number: '577' },
        { number: '244' },
        { number: '128' },
        { number: '589' },
        { number: '779' },
        { number: '167' },
        { number: '168' },
        { number: '277' },
        { number: '458' },
        { number: '468' },
        { number: '568' },
    ]
    useEffect(() => {
        console.log(pattiList.length);
    }, [])
    return (
        <div>
            <NavbarTop />
            <div className="pattilist-content">
                <div className="patti-header" style={{ paddingTop: '10px' }}>
                    <h2 className='h1PattiList'>Access Patti List</h2>
                    <p className='pattiListP'>Read the Patti List. There are 220 Number Combinations which you can use in the Triple Patti.</p>

                </div>
                <div className="pattilist-dets">
                    <div className="patti-list-child">
                        {
                            pattiList.map((patti, index) => (
                                <p>{patti.number}</p>
                            ))
                        }
                    </div>
                </div>
            </div>
            <NavbarBottom />
        </div>
    )
}

export default PattiList
