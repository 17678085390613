import React from 'react';
import './css/quicklinks.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWebAwesome } from '@fortawesome/free-brands-svg-icons';

const QuickLinks = () => {
    const links = [
        {
            title: 'Home',
            link: '/home'
        }, {
            title: 'Website',
            link: '/contact-us'
        },
        {
            title: 'Single Patti',
            link: '/single-patti'
        },
        {
            title: 'Triple Patti',
            link: '/triple-patti'
        },
        {
            title: 'Result',
            link: '/today-result'
        },
        {
            title: 'Contact Us',
            link: '/contact-us'
        },
    ];

    return (
        <div className="quick-links">
            {links.map((link, index) => (
                <div key={index} className="quick-link">
                    <Link to={link.link} className="link">
                        <button>
                            <FontAwesomeIcon icon={faWebAwesome}></FontAwesomeIcon>
                        </button>
                    </Link>
                    <span className="title">{link.title}</span>
                </div>
            ))}
        </div>
    );
};

export default QuickLinks;
