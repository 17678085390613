import React, { useEffect, useState } from 'react';
import NavbarTop from '../navbar/navbarTop';
import scanner from './../assets/scanner.jpeg';
import { auth, db } from '../config/firebase';
import NavbarBottom from '../navbar/navbarBottom';
import './css/fund.css';
import { Link } from 'react-router-dom';
import { query, getDocs, collection, where, addDoc } from 'firebase/firestore';

const AddFund = () => {
    const [walletBalance, setWalletBalance] = useState('-');
    const [amount, setAmount] = useState('');
    const [txnId, setTxnId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchUserProfile = async () => {
            console.log(auth.currentUser);
            try {
                const q = query(collection(db, "userProfile"), where("emailId", "==", auth.currentUser.email));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.log("No such document!");
                } else {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, " => ", doc.data());
                        setWalletBalance(doc.data().wallet);
                    });
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        };

        if (auth.currentUser) {
            fetchUserProfile();
        } else {
            console.log("No authenticated user.");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const currentUser = auth.currentUser;

        const fundRequest = {
            fundId: '',
            txnId,
            amount,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            emailId: currentUser.email,
            uid: currentUser.uid,
        };

        try {
            await addDoc(collection(db, "fund-request"), fundRequest);
            alert("Fund request added successfully");
        } catch (error) {
            console.error("Error adding fund request:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleQuickAmountClick = (value) => {
        setAmount(value.toString());
    };

    return (
        <div>
            <NavbarTop />
            <div className="addFundPage-container">
                <div className="addFund-content">
                    <div className="content-withdraw-page--fund-show">
                        <div className="balance-account--withdraw-fund">
                            <div className="wallet-balance--01">
                                <h3>Wallet Balance</h3>
                                <p>₹ {walletBalance}</p>
                            </div>
                            <Link>
                                <button className="addFund-01" disabled={isSubmitting}>
                                    {isSubmitting ? "ADDING REQUEST" : "Add Fund"}
                                </button>
                            </Link>
                        </div>
                    </div>
                    <h1>ADD FUND</h1>
                    <p className='fund-add-detail-p'>Enter the details of the fund you want to add</p>
                    <div className="scanner-image">
                        <img src={scanner} alt="Scanner" />
                    </div>
                    <div className="quick-amount-buttons">
                        {[100, 200, 500, 1000, 2000].map((value, index) => (
                            <button
                                key={index}
                                className="quick-amount-button"
                                onClick={() => handleQuickAmountClick(value)}
                            >
                                {value}
                            </button>
                        ))}
                    </div>
                    <form onSubmit={handleSubmit} className="fundstransfer-data" style={{ marginTop: '30px' }}>
                        <label htmlFor="amount" className="fundstransfer-child">
                            Amount Transferred
                            <input
                                type="text"
                                placeholder="Enter Amount"
                                className="fundstransfer-input"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                required
                            />
                        </label>
                        <label htmlFor="txnId" className="fundstransfer-child">
                            TXN ID
                            <input
                                type="text"
                                placeholder="Enter TXN ID"
                                className="fundstransfer-input"
                                value={txnId}
                                onChange={(e) => setTxnId(e.target.value)}
                                required
                            />
                        </label>
                        <div className="centerthebutton" style={{ width: '100%' }}>
                            <button type="submit" className="submitFundRequest" disabled={isSubmitting}>
                                {isSubmitting ? "ADDING REQUEST" : "Submit"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <NavbarBottom />
        </div>
    );
};

export default AddFund;
